.hero-wrapper {
  color: white;
  position: relative;
  z-index: 1;
  padding-bottom: 1;
  padding-top: 7rem; /* Add top padding */
  background-color: black;
}

.hero-container {
  justify-content: space-around;
  align-items: flex-end;
}

.hero-left {
  gap: 10rem;
}

.hero-title {
  position: relative;
  z-index: 1;
  margin-bottom: 2rem; /* Add margin to create space below the title */
}

.hero-title > h1 {
  font-weight: 600;
  font-size: 3.8rem;
  line-height: 4rem;
}

.orange-circle {
  height: 4rem;
  width: 4rem;
  background: var(--orange-gradient);
  border-radius: 999px;
  position: absolute;
  right: 16.5%;
  top: -10%;
  z-index: -1;
}

.stats {
  width: 100%;
  justify-content: space-between;
  transform: translateY(-70px); /* Adjust value as needed */
}

.stat > :nth-child(1) {
  font-size: 2rem;
}

.stat > :nth-child(1) > :last-child {
  color: #59a135;
}

.image-container {
  width: 35rem;
  height: 37rem;
  overflow: hidden;
}

.image-container > img {
  width: 100%;
  height: 100%;
}

/* mobile screens */
@media (max-width: 640px) {
  .hero-container {
    margin-top: -2rem;
  }

  .hero-title > h1 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .image-container {
    width: 100%;
    height: 20rem;
    margin-top: -50px;
  }

  .stats {
    justify-content: center;
    gap: 1.5rem;
    font-weight: wrap;
    margin-top: -30px;
  }

  .stat > :nth-child(1) {
    font-size: 1.5rem;
  }

  .stat > :nth-child(2) {
    font-size: 0.8rem;
  }
}
